export const iconSet = {
  active: 'assets/icons/play-solid.svg',
  add: 'assets/icons/plus-solid.svg',
  applications: 'assets/icons/bars-progress-solid.svg',
  arrowRight: 'assets/icons/arrow-right-from-bracket-solid.svg',
  cancel: 'assets/icons/xmark-solid.svg',
  caretLeft: 'assets/icons/caret-left-solid.svg',
  caretRight: 'assets/icons/caret-right-solid.svg',
  clipboard: 'assets/icons/clipboard-regular.svg',
  criticalError: 'assets/icons/fire-solid.svg',
  delete: 'assets/icons/trash-can-solid.svg',
  edit: 'assets/icons/pen-to-square-solid.svg',
  error: 'assets/icons/xmark-solid.svg',
  infrastructure: 'assets/icons/network-wired-solid.svg',
  loading: 'assets/icons/circle-notch-solid.svg',
  paused: 'assets/icons/pause-solid.svg',
  permissions: 'assets/icons/key-solid.svg',
  reset: 'assets/icons/rotate-left-solid.svg',
  sync: 'assets/icons/rotate-solid.svg',
  tenants: 'assets/icons/building-solid.svg',
  users: 'assets/icons/users-solid.svg',
  view: 'assets/icons/eye-solid.svg'
}
